import React from "react"

const AvatarSignature = () => {
  return (
    <div className="avatar-signature">
      <img className="avatar" src="./images/avatar-pat.jfif" alt="avatar-pat" />
      <div>
        <p>Patrick Touchette</p>
        <p>Développeur Web</p>
      </div>
    </div>
  )
}

export default AvatarSignature

import React from "react"

const ContactForm = () => {
  return (
    <div className="contact">
      <h4 className="title">Avez-vous une question?</h4>
      <form name="contact-pat" method="POST" data-netlify="true">
        <textarea
          aria-label="question-input"
          name="question"
          placeholder="Votre question..."
        ></textarea>
        <div className="group">
          <input
            aria-label="email-input"
            name="email"
            type="email"
            placeholder="Votre courriel"
            required
          />
          <button className="btn btn-primary">Poser une question</button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm

import React from "react"
import chantalayoga from "../../static/images/chantalayoga.jpg"
import patricktouchette from "../../static/images/patricktouchette.jpg"

const PortfolioItem = ({ title, img, alt, text, linkUrl }) => {
  return (
    <div className="portfolio-item">
      <img src={img} alt={alt} />
      <h4 className="item-title">{title}</h4>
      <p>{text}</p>
      <a
        href={linkUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-secondary"
      >
        Voir le site
      </a>
    </div>
  )
}

const Portfolio = () => {
  return (
    <div className="portfolio">
      <PortfolioItem
        title="chantalayoga.ca"
        img={chantalayoga}
        alt="chantalayoga website"
        text="Site web pour un studio de yoga près de Orford."
        linkUrl="https://chantalayoga.ca"
      />

      <PortfolioItem
        title="patricktouchette.com"
        img={patricktouchette}
        alt="patrick touchette web"
        text="Mon propre site web. Vous êtes ici."
        linkUrl="https://patricktouchette.com"
      />
    </div>
  )
}

export default Portfolio

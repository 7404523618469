import React from "react"

const Blurb = () => {
  return (
    <div className="blurb">
      <h3>
        Je <span className="accent"> crée</span> des sites web et des
        applications web.
      </h3>
    </div>
  )
}

export default Blurb

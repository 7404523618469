import React from "react"
import AvatarSignature from "./avatarSignature"

const About = () => {
  return (
    <section className="about">
      <div className="content">
        <p>Je suis passionné de la programmation et du web.</p>

        <p>
          En 2015, je débutais en tant qu'ingénieur junior en aéronautique.
          C'est là que j'ai commencé à écrire du
          <strong> code</strong> pour automatiser mes tâches répétitives.
        </p>

        <p>
          En 2018, j'ai quitté le domaine de l'aéronautique pour travailler en
          tant que développeur web ici à Bromont. Ma mission: implémenter des
          solutions informatiques pour résoudre des problèmes réels.
        </p>

        {/* <p>
          La conception de sites web me permet d'explorer le côté
          <strong> créatif</strong> du web tout en ajoutant de la valeur à votre
          entreprise.
        </p> */}
      </div>

      <div className="image">
        <img src="./images/undraw_programming_2svr.svg" alt="programming" />
      </div>

      <AvatarSignature />
    </section>
  )
}

export default About

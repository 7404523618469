import React from "react"

import colors from "../styles/colors.js"
import SEO from "../components/SEO.js"
import Hero from "../components/hero"
import Blurb from "../components/blurb"
import Portfolio from "../components/portfolio"
import Section from "../components/section"
import About from "../components/about"
import ContactForm from "../components/contactForm"
import Footer from "../components/footer"

const Index = () => {
  const { secondary, neutral, dark } = colors

  return (
    <div className="home-page">
      <SEO pathname="" lang="fr" />

      <Section>
        <Hero />
      </Section>

      <Section bg={dark} color="white">
        <Blurb />
      </Section>

      {/* <Section id="features" title="Avantages">
        <Features />
      </Section> */}

      <Section id="portfolio" bg={neutral} title="Dernières réalisations">
        <Portfolio />
      </Section>

      {/* <Section bg="white" color={dark} id="pricing" title="Tarifs">
        <Pricing />
      </Section> */}

      <Section bg="white" id="about" title="À propos de moi">
        <About />
      </Section>

      <Section bg={secondary} id="contact">
        <ContactForm />
      </Section>

      <Section bg={dark} color="white">
        <Footer />
      </Section>
    </div>
  )
}

export default Index

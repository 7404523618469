import React from "react"
import Navigation from "./navigation"
import Background from "./background"

const Hero = () => {
  return (
    <header className="header">
      <Background />
      <Navigation />

      <div className="header-hero">
        <div>
          <h1>Patrick Touchette</h1>
          <h3>
            <em>Développeur Web</em>
          </h3>
          {/* <p>
            Je crée des <span className="accent">sites web</span> pour les
            entrepreneurs, les PME et les travailleurs autonomes.
          </p> */}
        </div>
      </div>
    </header>
  )
}

export default Hero
